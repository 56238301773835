import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from "prop-types"
import Helmet from 'react-helmet'
import Slider from "react-slick"

import TemplateWrapper from '../components/layout'
import Player from '../components/Video/player'
import Title from '../components/title'

class SingleWork extends Component {

  render() {

    let data = this.props.data.wordpressWpWork

    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      cssEase: 'ease',
      customPaging: (i) => {
        return <span>{i + 1}/2</span>
      }
    }

    let video = {
      title: data.title,
      video_url: data.acf.video_url,
      video_placeholder: data.acf.video_placeholder ? data.acf.video_placeholder.source_url : null,
    }

    return (
      <TemplateWrapper {...this.props}>
        <div>
          <Helmet
            bodyAttributes={{
              class: 'single-work'
            }}
          >
            <title>{ data.yoast_meta.yoast_wpseo_title }</title>
            <meta name="description" content={ data.yoast_meta.yoast_wpseo_metadesc } />
          </Helmet>

          <div className="r3__head r3--dark">
            { data.acf.video_url && <Player {...video} controls={true} muted={false} autoplay={true} /> }
          </div>

          <div className="r3__section">
            <div className="r3__content">
              <Title text={ data.title || 'loading' } />
              { data.acf.introduction !== '' &&
                <div className="r3__narrow">
                  <div className="r3__introduction" dangerouslySetInnerHTML={{__html: data.acf.introduction}}></div>
                </div>
              }
            </div>
          </div>

          { data.acf.gallery ?
            <div className="r3__section r3__section--slider">
              <div className="r3__content">
                <Slider {...settings}>
                  { data.acf.gallery.map((el, i) => {
                    return <img src={el.source_url} key={i} alt={el.title} />
                  }) }
                </Slider>
              </div>
            </div> : ''
          }

          { data.acf.content &&
            <div className="r3__section">
              <div className="r3__content">
                <div className="r3__narrow" dangerouslySetInnerHTML={{__html: data.acf.content}}></div>
              </div>
            </div>
          }

        </div>
      </TemplateWrapper>
    )
  }
}

SingleWork.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default SingleWork

export const pageQuery = graphql`
  query currentWorkQuery($id: String!) {
    wordpressWpWork(id: { eq: $id }) {
      wordpress_id
      slug
      title
      work_category
      acf {
        video_url
        video_placeholder {
          source_url
        }
        introduction
        gallery {
          source_url
          title
        }
        content
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
